<template>
    <v-main>
        <v-slide-x-transition mode="out-in">
            <billing-list v-if="this.$route.name === 'billing'"/>
        </v-slide-x-transition>
    </v-main>
</template>

<script>
import BillingList from '../components/Billing'

export default {
  name: 'XList',
  components: {
    BillingList,
  },
  metaInfo: {
    // all titles will be injected into this template
    titleTemplate: '%s'
  }
}
</script>

<style lang="scss">
    .v-datatable, .v-data-iterator {
        thead i {
            line-height: 0.3;
            margin-left: 5px;
        }
        &__actions i {
            font-size: 14px;
        }
    }
</style>
