<template>
    <div></div>
</template>

<script>
export default {
  name: 'NotFound',
  mounted: function () {
    this.$router.push('/billing')
  }
}
</script>
