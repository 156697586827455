<template>
    <v-container v-if="UserInfo.email">
      <div style="color: white; display: flex; margin-top:-50px; margin-bottom: 20px;">
        <div style="background-color: #7f91a4; width:50px; height:50px; border-radius: 50%; margin-right: 10px;">
          <svg-icon type="mdi" :path="credit_card" style="width:30px; height:30px; margin: 10px;"></svg-icon>
        </div>
        <h1>Баланс: {{UserInfo.balance}} ₽</h1>
      </div>

      <div class="tabs">
        <div class="tab" ref="Pay" @click="selectTab('Pay'); getPayTab()">Оплата</div>
        <div class="tab" ref="Control" @click="selectTab('Control'); getPayTab()">Управление</div>
        <div class="tab" ref="History" @click="selectTab('History'); getOperationsTab()">История операций</div>
      </div>
        
      <v-form style="width: 55%; margin-top: 20px;" v-if="current_tab=='Pay'">
        <v-autocomplete
          dark
          label="Период оплаты"
          :items="PayTab.payment_periods"
          v-model="PayTab.payment_period"
          outlined
          color="#454cee"
          disabled
        >
        </v-autocomplete>
        <v-text-field
          dark
          label="Облачные услуги"
          v-model="PayTab.amount"
          outlined
          append-icon="mdi-currency-rub"
          color="#454cee"
        >
        </v-text-field>
        <v-autocomplete
          dark
          label="Способ оплаты"
          :items="PayTab.payment_methods"
          v-model="PayTab.payment_method"
          outlined
          color="#454cee"
          prepend-inner-icon="mdi-credit-card"
          disabled
        >
        </v-autocomplete>

        <div style="display: flex; width: 100%; flex-wrap: wrap;">
          <v-card
            class="credit_card"
            :style="{ 'background-color': '#17212b', 'border': PayTab.uuid_card == item.uuid ? '2px solid #454cee' : '1px solid #7f91a4' }"
            @click="PayTab.uuid_card=item.uuid"
            v-for="(item, index) in Cards" :key="index"
          >
            <v-card-title style="color: white">
              <div v-html="getBankIcon(item.card_type, '40px', '30px')"></div>
            </v-card-title>
            <v-card-subtitle style="color: white; float: right;">**** {{item.last4}}</v-card-subtitle>
            <v-card-text style="color: white">{{item.expiry_month}}/{{item.expiry_year}}</v-card-text>
          </v-card>
          <v-card
            class="credit_card"
            :style="{ 'background-color': '#17212b', 'border': PayTab.uuid_card == null ? '2px solid #454cee' : '1px solid #7f91a4' }"
            @click="PayTab.uuid_card=null"
          >
            <v-card-title style="color: white">Новая карта</v-card-title>
            <v-card-subtitle style="color: white"></v-card-subtitle>
            <v-card-text style="color: white; display: flex;">
              <div v-html="getBankIcon('Visa', '33px', '28px')"></div>
              <div v-html="getBankIcon('MasterCard', '33px', '28px')"></div>
              <div v-html="getBankIcon('Mir', '33px', '28px')"></div>
              <div v-html="getBankIcon('JCB', '33px', '28px')"></div>
            </v-card-text>
          </v-card>
        </div>
        <v-checkbox color="#454cee" dark class="mx-2" v-model="PayTab.NewCard.save_payment_method" label="Привязать карту для удобного пополнения баланса" v-if="PayTab.uuid_card==null"></v-checkbox>
        <div style="margin-top: 15px;" v-else></div>
        <v-btn style="margin-left: 1%; background-color: #454cee; color: white" @click="pay(PayTab)">Оплатить</v-btn>
      </v-form>

      <v-data-table
        hide-default-footer
        dark
        style="background-color: #17212b; color: white; margin-top: 20px;"
        :items="Cards"
        no-data-text="Нет элементов для управления"
        v-if="current_tab=='Control'"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              <svg-icon type="mdi" :path="credit_card"></svg-icon>
            </td>
            <td>
              <div>Привязанная карта</div>
              <div>{{ props.item.card_type }}</div>
              <div>**** {{ props.item.last4 }}</div>
            </td>
            <td>
              <v-btn style="margin-left: 1%; background-color: #4a5867; color: white" @click="unlinkBankCard(props.item.uuid)">Отвязать карту</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-data-table
        dark
        style="background-color: #17212b; color: white; margin-top: 20px;"
        :headers="OperationsTab.headers"
        :items="OperationsTab.operations"
        no-data-text="Нет операций"
        :options.sync="OperationsTab.pagination_operation"
        :footer-props="{
          'items-per-page-options': OperationsTab.pagination_operation.rowsPerPageItems
        }"
        :server-items-length="OperationsTab.pagination_operation.totalItems"
        v-if="current_tab=='History'"
      >
        <template v-slot:item="props">
          <tr :disable-hover=true>
            <td>{{ formatDate(props.item.created_at) }}</td>
            <td>{{ props.item.amount }} ₽</td>
            <td>{{ props.item.status }}</td>
            <td style="color: #7f91a4">{{ props.item.description }}</td>
          </tr>
        </template>
      </v-data-table>


      <v-dialog width="40%" style="min-width: 285px" v-model="PayTab.NewCard.show" @keyup.esc="closeNewCardModal()" overlay-color="#26325c80">
        <v-card style="background-color: #17212b; color: white; min-width: 285px">
          <v-card-title style="justify-content: center;">Оплата банковской картой</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                dark
                label="number"
                v-model="PayTab.NewCard.number"
                :rules="[validateCreditCardNumber]"
                :error-messages="error_msg.CreditCardNumber"
                v-mask="'####-####-####-####'"
              >
              </v-text-field>
              <v-text-field
                dark
                type="cvc"
                label="cvc"
                v-model="PayTab.NewCard.cvc"
                :rules="[validateCreditCardCvc]"
                :error-messages="error_msg.CreditCardCvc">
              </v-text-field>
              <v-text-field
                dark
                label="month"
                v-model="PayTab.NewCard.expiry_month"
                :rules="[validateCreditCardExpiryMonth]"
                :error-messages="error_msg.CreditCardExpiryMonth">
              </v-text-field>
              <v-text-field
                dark
                label="year"
                v-model="PayTab.NewCard.expiry_year"
                :rules="[validateCreditCardExpiryYear]"
                :error-messages="error_msg.CreditCardExpiryYear">
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn style="margin-left: 1%; background-color: #454cee; color: white" @click="pay(PayTab)">Оплатить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container v-else>
      <h1 style="color: white;">Требуется авторизация</h1>
    </v-container>
  </template>
  <script>
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiCreditCardOutline } from '@mdi/js';
  import {bus} from '../main';
  export default {
    name: 'BillingList',
    metaInfo: {
      title: 'Биллинг | srvCloud'
    },
    components: {
      SvgIcon
    },
    created() {
      const script = document.createElement('script');
      script.src = 'https://static.yoomoney.ru/checkout-js/v1/checkout.js';
      document.head.appendChild(script);
      bus.$on('UserInfo', data => {
          this.UserInfo = data;
      })
    },
    mounted() {
      this.getPayTab()
      const intervalId = setInterval(() => {
        if (this.$refs['Pay']) {
          this.$refs['Pay'].classList.add('tab_focus')
          clearInterval(intervalId)
        }
      }, 100)
    },
    data: function () {
      return {
        connection: null,
        credit_card: mdiCreditCardOutline,
        selectedCard: null,
        YooMoney: null,
        current_tab: 'Pay',
        Cards: [],
        PayTab: {
          payment_periods: ['Произвольная сумма'],
          payment_period: 'Произвольная сумма',
          amount: 0,
          payment_methods: ['Российская банковская карта'],
          payment_icons: ["mdi-credit-card"],
          payment_method: 'Российская банковская карта',
          uuid_card: null,
          NewCard: {
            show: false,
            number: '',
            cvc: '',
            expiry_month: '',
            expiry_year: '',
            save_payment_method: true
          },
        },
        OperationsTab: {
          operations: [],
          pagination_operation: {
            descending: false,
            page: 1,
            itemsPerPage: 10,
            totalItems: 0,
            rowsPerPageItems: [10, 25, 50, 100]
          },
          headers: [
            {
              text: 'Дата',
              value: 'created_at',
              sortable: true
            },
            {
              text: 'Сумма',
              value: 'amount',
              sortable: true
            },
            {
              text: 'Статус',
              value: 'status',
              sortable: false
            },
            {
              text: 'Событие',
              value: 'description',
              sortable: false
            },
          ]
        },
        error_msg: {
          CreditCardNumber: [],
          CreditCardCvc: [],
          CreditCardExpiryMonth: [],
          CreditCardExpiryYear: []
        },
        UserInfo: {
          email: null,
          name: null,
          sub: null,
          balance: null
        },
      }
    },
    watch: {
      'OperationsTab.pagination_operation.page': {
        handler: 'getOperationsTab'
      },
      'OperationsTab.pagination_operation.itemsPerPage': {
        handler: 'getOperationsTab'
      }
    },
    methods: {
      tokenize: async function(card) {
        const YooMoney = window.YooMoneyCheckout(process.env.VUE_APP_YOOKASSA_SHOPID)
        var paymentToken = null
        await YooMoney.tokenize({
          number: card.number,
          cvc: card.cvc,
          month: card.expiry_month,
          year: card.expiry_year
        }).then(res => { 
          if (res.status == 'success') {
            paymentToken = res.data.response;
          } else if (res.status == 'error') {
            for (let param of res.error.params) {
              if (param.code == "invalid_number") {
                this.error_msg.CreditCardNumber = [param.message]
              } else if (param.code == "processing_error") {
                this.error_msg.CreditCardNumber = [param.message]
              } else if (param.code == "invalid_expiry_month") {
                this.error_msg.CreditCardExpiryMonth = [param.message]
              } else if (param.code == "invalid_expiry_year") {
                this.error_msg.CreditCardExpiryYear = [param.message]
              } else if (param.code == "invalid_cvc") {
                this.error_msg.CreditCardCvc = [param.message]
              } else if (param.code == "missing") {
                this.error_msg.CreditCardNumber = [param.message]
              } else {
                this.$toasted.error('Что-то пошло не так... Попробуйте позднее', { position: 'top-center' }).goAway(3000)
              }
            }
          }
        }).catch(err => {
          err = 'Что-то пошло не так... Попробуйте позднее'
          this.$toasted.error(err, { position: 'top-center' }).goAway(3000)
        }
        );
        return paymentToken
      },
      pay: async function(PayTab) {
        var params = null
        if (PayTab.uuid_card == null) {
          if (PayTab.NewCard.show == true) {
            if (this.validateCreditCard(PayTab.NewCard) == false) {
              return 0
            }
            PayTab.NewCard.number = PayTab.NewCard.number.replace('-', '')
            while (PayTab.NewCard.number.includes('-')) {
              PayTab.NewCard.number = PayTab.NewCard.number.replace('-', '');
            }
            var token = await this.tokenize(PayTab.NewCard)
            if (token != null) {
              params = {
                amount: PayTab.amount,
                payment_token: token.paymentToken,
                save_payment_method: PayTab.NewCard.save_payment_method
              }
            }
          } else{
            PayTab.NewCard.show = true
          }
        } else {
          params = {
            amount: PayTab.amount,
            payment_method_id: PayTab.uuid_card,
            save_payment_method: false
          }
        }
        if (params != null) {
          this.$store.dispatch('billing/pay', params).then(response => {
            let data =  response.data || {}
            if (data.status =='pending') {
              window.location.href = data.confirmation.confirmation_url
            }
          }).catch(error => {
            console.log(error)
          })
        }
      },
      unlinkBankCard: function(card_uuid) {
        let params = {
          uuid: card_uuid
        }
        this.$store.dispatch('billing/unlink_card', params).then(
          this.getPayTab()
        ).catch(error => {
          console.log(error)
        })
      },
      getOperationsTab: function() {
        let params = {
          page: this.OperationsTab.pagination_operation.page,
          limit: this.OperationsTab.pagination_operation.itemsPerPage
        }
        this.$store.dispatch('billing/operations', params).then(response => {
          this.OperationsTab.operations = (response.data || {}).data || {}
          this.OperationsTab.pagination_operation.totalItems = (response.data || {}).total_records || 0
        }).catch(error => {
          console.log(error)
        })
      },
      getPayTab: function() {
        this.$store.dispatch('billing/cards').then(response => {
          this.Cards = response.data || []
        }).catch(error => {
          console.log(error)
        })
      },
      selectTab(name_tab) {
        Object.keys(this.$refs).forEach(key => {
          if (key != name_tab) {
            this.$refs[key].classList.remove('tab_focus')
          } else {
            this.$refs[key].classList.add('tab_focus')
          }
        })
        this.current_tab = name_tab
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleString('ru-RU', options);
      },
      getBankIcon: function(bank_key, width, height) {
        if (bank_key == 'Visa') {
          return `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="visa" style="background-color: white; border-radius: 10%; width: `+width+`; height:`+height+`; margin: 2px;" >
            <path fill="#191E6E" d="M13.967 13.837c-.766 0-1.186-.105-1.831-.37l-.239-.109-.271 1.575c.466.192 1.306.357 2.175.37 2.041 0 3.375-.947 3.391-2.404.016-.801-.51-1.409-1.621-1.91-.674-.325-1.094-.543-1.094-.873 0-.292.359-.603 1.109-.603a3.602 3.602 0 0 1 1.455.269l.18.08.271-1.522-.047.01a5.053 5.053 0 0 0-1.74-.297c-1.92 0-3.275.954-3.285 2.321-.012 1.005.964 1.571 1.701 1.908.757.345 1.01.562 1.008.872-.005.471-.605.683-1.162.683zm8.461-5.655h-1.5c-.467 0-.816.125-1.021.583l-2.885 6.44h2.041l.408-1.054 2.49.002c.061.246.24 1.052.24 1.052H24l-1.572-7.023zM20.03 12.71l.774-1.963c-.01.02.16-.406.258-.67l.133.606.449 2.027H20.03zM8.444 15.149h1.944l1.215-7.026H9.66v-.002zM4.923 12.971l-.202-.976v.003l-.682-3.226c-.117-.447-.459-.579-.883-.595H.025L0 8.325c.705.165 1.34.404 1.908.697a.392.392 0 0 1 .18.234l1.68 5.939h2.054l3.061-7.013H6.824l-1.901 4.789z"></path>
          </svg>`
        } else if (bank_key == 'MasterCard') {
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="mastercard" style="background-color: white; border-radius: 10%; width: `+width+`; height:`+height+`; margin: 2px;" >
              <path fill="#FF5F00" d="M15.245 17.831h-6.49V6.168h6.49v11.663z"></path>
              <path fill="#EB001B" d="M9.167 12A7.404 7.404 0 0 1 12 6.169 7.417 7.417 0 0 0 0 12a7.417 7.417 0 0 0 11.999 5.831A7.406 7.406 0 0 1 9.167 12z"></path>
              <path fill="#F79E1B" d="M24 12a7.417 7.417 0 0 1-12 5.831c1.725-1.358 2.833-3.465 2.833-5.831S13.725 7.527 12 6.169A7.417 7.417 0 0 1 24 12z"></path>
            </svg>`
        } else if (bank_key == 'Mir') {
          return `
            <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="background-color: white; border-radius: 10%; width: `+width+`; height:`+height+`; margin: 2px;" >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.024 8.251h.043c.301-.005 1.038-.018 1.36 1.067.226.768.588 2.026 1.085 3.774h.203c.532-1.843.898-3.1 1.098-3.774.32-1.083 1.093-1.072 1.467-1.067h2.697v8.192h-2.68v-4.827h-.18l-1.495 4.827H6.605l-1.494-4.831h-.18v4.831H2.25V8.251h2.774Zm11.803 0v4.832h.213l1.818-3.973a1.44 1.44 0 0 1 1.105-.859h2.594v8.192h-2.736v-4.831h-.214l-1.782 3.973c-.353.787-1.141.858-1.141.858H14.09V8.251h2.736Zm12.282 5.752c1.327 0 2.526-.776 2.908-1.859h-8.38v4.3h2.602v-2.441h2.87Z" fill="#40A548"></path><path d="M29.235 8.252H23.5c.136 1.824 1.705 3.385 3.33 3.385h5.368c.31-1.516-.757-3.385-2.963-3.385Z" fill="#1291D0"></path>
            </svg>`
        } else if (bank_key == 'JCB') {
            return `<svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="background-color: white; border-radius: 10%; width: `+width+`; height:`+height+`; margin: 2px;" >
                <path d="M23.316 13.928h1.731c.05 0 .165-.017.215-.017a.772.772 0 0 0 .61-.774.8.8 0 0 0-.61-.775c-.05-.017-.149-.017-.215-.017h-1.73v1.583Z" fill="url(#jcb_svg__a)"></path>
                <path d="M24.85 3a3 3 0 0 0-3 3v3.115h4.236c.099 0 .214 0 .297.017.956.05 1.664.544 1.664 1.401 0 .676-.478 1.253-1.368 1.368v.033c.973.066 1.715.61 1.715 1.45 0 .907-.825 1.5-1.913 1.5h-4.648v6.1h4.401a3 3 0 0 0 3-3V3H24.85Z" fill="#018540"></path>
                <path d="M25.657 10.731a.7.7 0 0 0-.61-.709c-.033 0-.115-.016-.165-.016h-1.566v1.45h1.566c.05 0 .149 0 .165-.016.33-.05.61-.313.61-.709Z" fill="url(#jcb_svg__b)"></path>
                <path d="M8.266 3a3 3 0 0 0-3 3v7.401c.841.412 1.715.676 2.588.676 1.039 0 1.6-.627 1.6-1.484V9.1h2.57v3.478c0 1.352-.84 2.456-3.692 2.456-1.73 0-3.082-.38-3.082-.38v6.314h4.401a3 3 0 0 0 3-3V3H8.266Z" fill="#06488E"></path>
                <path d="M16.558 3a3 3 0 0 0-3 3v3.923c.758-.643 2.077-1.055 4.203-.956 1.137.05 2.357.363 2.357.363v1.269c-.61-.313-1.335-.594-2.275-.66-1.615-.115-2.588.676-2.588 2.061 0 1.401.973 2.192 2.588 2.06.94-.066 1.665-.362 2.275-.659v1.27s-1.203.313-2.357.362c-2.126.099-3.445-.313-4.203-.956V21h4.4a3 3 0 0 0 3-3V3h-4.4Z" fill="#BD1933"></path>
                <defs>
                  <linearGradient id="jcb_svg__a" x1="21.846" y1="13.138" x2="29.254" y2="13.138" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#007940"></stop><stop offset="0.229" stop-color="#00873F"></stop>
                    <stop offset="0.743" stop-color="#40A737"></stop><stop offset="1" stop-color="#5CB531"></stop>
                  </linearGradient>
                  <linearGradient id="jcb_svg__b" x1="21.846" y1="10.729" x2="29.254" y2="10.729" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#007940"></stop><stop offset="0.229" stop-color="#00873F"></stop>
                    <stop offset="0.743" stop-color="#40A737"></stop><stop offset="1" stop-color="#5CB531"></stop>
                  </linearGradient>
                </defs>
              </svg>`
        } else {
          return `<div v-else>`+bank_key+`</div>`
        }
      },
      validateCreditCard: function(data) {
        let _return = true
        const hasOnlyDigits = (v) => /^\d+$/.test(v)
        var number = data.number.replace('-', '')
        while (number.includes('-')) {
          number = number.replace('-', '');
        }
        if (number == '') {
          this.error_msg.CreditCardNumber = ['Поле обязательно для заполнения']
          _return = false
        }
        if (number != '' && (number.length != 16 || !hasOnlyDigits(number))) {
          this.error_msg.CreditCardNumber = ['Некорректный номер карты']
          _return = false
        }
        if (data.cvc == '') {
          this.error_msg.CreditCardCvc = ['Поле обязательно для заполнения']
          _return = false
        }
        if (data.cvc != '' && (data.cvc.length < 3 || data.cvc.length > 4 || !hasOnlyDigits(data.cvc))) {
          this.error_msg.CreditCardCvc = ['Некорректный cvc']
          _return = false
        }
        if (data.expiry_month == '') {
          this.error_msg.CreditCardExpiryMonth = ['Поле обязательно для заполнения']
          _return = false
        }
        if (data.expiry_month != '' && (data.expiry_month.length != 2  || !hasOnlyDigits(data.expiry_month))) {
          this.error_msg.CreditCardExpiryMonth = ['Некорректный месяц']
          _return = false
        }
        if (data.expiry_year == '') {
          this.error_msg.CreditCardExpiryYear = ['Поле обязательно для заполнения']
          _return = false
        }
        if (data.expiry_year != '' && (data.expiry_year.length != 2  || !hasOnlyDigits(data.expiry_year))) {
          this.error_msg.CreditCardExpiryYear = ['Некорректный год']
          _return = false
        }
        return _return
      },
      validateCreditCardCvc: function() {
        this.error_msg.CreditCardCvc = []
        return true
      },
      validateCreditCardExpiryYear: function() {
        this.error_msg.CreditCardExpiryYear = []
        return true
      },
      validateCreditCardExpiryMonth: function() {
        this.error_msg.CreditCardExpiryMonth = []
        return true
      },
      validateCreditCardNumber: function() {
        this.error_msg.CreditCardNumber = []
        return true
      },


    }
  }
  </script>

<style>
  .tabs {
    display: flex;
    border-bottom: 1px solid #7f91a4;
    position: relative;
  }
  .tab {
    display: flex;
    cursor: pointer;
    color: #7f91a4;
    margin-right: 10px;
    font-weight: bold;
    padding-bottom: 18px;
  }
  .tab:hover {
    color: #ffffff;
  }
  .tab_focus {
    color: #ffffff;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ffffff;
  }
  .credit_card{
    background-color: #17212b;
    min-width: 48%;
    margin: 1%;
  }
</style>
