import XList from '../views/XList'
import NotFound from '../views/NotFound'

export default [
    
    {
        name: 'billing',
        path: '/billing',
        component: XList,
    },
    {
        name: 'not_found',
        path: '*',
        component: NotFound
    },
]
