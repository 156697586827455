import api from '../../../plugins/api';

export default {
  pay: function (_, payload) {
    return api.post('/billing/pay/', payload)
  },
  cards: function(_, params) {
    return api.get(
        '/billing/cards/', {
            params: params
        }
    )
  },
  operations: function(_, params) {
    return api.get(
        '/billing/operations/', {
            params: params
        }
    )
  },
  unlink_card: function(_, params) {
    return api.delete(
      '/billing/cards/', {
        params: params
      }
    )
  }
}
