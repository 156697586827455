<template>
  <v-app style="background-color: #17212B; font-family: Stem;">
    
    <v-app-bar app style="background-color: #242f3d;">
      <v-app-bar-title>
        <h3 class="text-h4 white--text">srv>cloud</h3>
      </v-app-bar-title>
      <v-spacer></v-spacer>

      <div style="display: flex; margin-left: 1%; margin-right: 1%; cursor: pointer;" class="icon_chameleon" @click="$router.push('/billing');" v-if="UserInfo.balance">
        <svg-icon type="mdi" :path="wallet"></svg-icon>
        <div style="margin-left: 5px; color: white;">{{ UserInfo.balance }} ₽</div>
      </div>
      <div class="icon_chameleon">
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="display: flex; margin-left: 1%; margin-right: 1%;">
              <svg-icon type="mdi" :path="techsupport"></svg-icon>
              <div style="margin-left: 5px; margin-right: 5px; color: white;">Поддержка</div>
              <svg-icon type="mdi" :path="chevron"></svg-icon>
            </div>
          </template>
          <v-list style="background-color: #17212B; border-radius: 10px; border-style: solid; border-width: 1px; border-color: #242f3d;">
            <v-list-item style="color: white; cursor: pointer;" class="menu_el" v-for="(item, index) in MenuSupport" :key="index">
              <v-list-item-icon style="color: #7f91a4; margin-right: 5px;">
                <svg-icon type="mdi" :path="item.icon"></svg-icon>
              </v-list-item-icon>
              <v-list-item-title style="font-size: small;">{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="icon_chameleon" v-if="UserInfo.email">
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="display: flex; margin-left: 1%; margin-right: 1%;">
              <div style="margin-left: 5px; margin-right: 5px; color: white;">{{UserInfo.email.split('@')[0]}}</div>
              <svg-icon v-bind="attrs" v-on="on" type="mdi" :path="account" style="color: #ffffff;"></svg-icon>
              <svg-icon type="mdi" :path="chevron"></svg-icon>
            </div>
          </template>
          <v-list style="background-color: #17212B; border-radius: 10px; border-style: solid; border-width: 1px; border-color: #242f3d;">
            <v-list-item style="color: white; cursor: pointer;" class="menu_el" v-for="(item, index) in MenuUser" :key="index">
              <v-list-item-title style="font-size: small;">{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div style="display: flex; margin-left: 1%; cursor: pointer;" class="icon_chameleon" @click="openLogInModal()" v-else>
        <svg-icon type="mdi" :path="account"></svg-icon>
        <div style="margin-left: 5px; color: white;">Войти</div>
      </div>
      <v-btn style="margin-left: 1%; background-color: #454cee; color: white" @click="openSignUpModal()" v-if="!UserInfo.email">Регистрация</v-btn>
    </v-app-bar>
    
    <v-dialog width="40%" style="min-width: 285px" v-model="LogInModal.show" @keyup.esc="closeLogInModal()" overlay-color="#26325c80">
      <v-card style="background-color: #17212b; color: white; min-width: 285px">
        <v-card-title style="justify-content: center;">Вход в srvCloud</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field dark label="Емайл" v-model="LogInModal.email" :rules="[validateEmail]" :error-messages="error_msg.LogInEmail"></v-text-field>
            <v-text-field 
              dark
              label="Пароль"
              type="password"
              v-model="LogInModal.password" 
              :rules="[validatePassword]"
              :error-messages="error_msg.LogInPassword">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn style="background-color: #454cee; color: white; margin-left: auto; margin-right: auto;" text
            @click="LogIn(LogInModal)">Войти в панель управления
          </v-btn>
        </v-card-actions>
        <v-card-text style="display: flex; justify-content: center; margin-top: 15px;">
          <div style="color: white; margin-right: 5px;">Нет аккаунта?</div>
          <a style="color: #454cee" @click="openSignUpModal()">Зарегистрируйтесь</a>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog width="40%" style="min-width: 285px" v-model="SignUpModal.show" @keyup.esc="closeSignUpModal()" overlay-color="#26325c80">
      <v-card style="background-color: #17212b; color: white; min-width: 285px">
        <v-card-title style="justify-content: center;">Регистрация в srvCloud</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field dark label="ФИО" v-model="SignUpModal.full_name" :rules="[validateFullName]" :error-messages="error_msg.SignUpFullName"></v-text-field>
            <v-text-field dark label="Емайл" v-model="SignUpModal.email" :rules="[validateEmail]" :error-messages="error_msg.SignUpEmail"></v-text-field>
            <v-text-field 
              dark
              label="Пароль"
              type="password"
              v-model="SignUpModal.password" 
              :rules="[validatePassword]"
              :error-messages="error_msg.SignUpPassword">
            </v-text-field>
            <v-text-field 
              dark
              label="Повторите пароль"
              type="password"
              v-model="SignUpModal.password_repeat" 
              :rules="[validatePassword]"
              :error-messages="error_msg.SignUpPasswordRepeat">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn style="background-color: #454cee; color: white; margin-left: auto; margin-right: auto;" text
            @click="SignUp(SignUpModal)">Зарегистрироваться
          </v-btn>
        </v-card-actions>
        <v-card-text style="display: flex; justify-content: center; margin-top: 15px;">
          <div style="color: white; margin-right: 5px;">Уже есть аккаунт?</div>
          <a style="color: #454cee" @click="openLogInModal()">Войдите</a>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiWalletBifoldOutline, mdiAccountCircle, mdiHeadphones, mdiChevronDown, mdiSendVariant, mdiMessageProcessing } from '@mdi/js';
import {bus} from './main';

export default {
  name: 'App',
  components: {
    SvgIcon
  },
  beforeDestroy() {
    if (this.connection != null) {
      this.connection.close(1000, "close connection")
    }
  },
  metaInfo: {
    title: 'srvCloud'
  },
  data() {
    return {
      connection: null,
      wallet: mdiWalletBifoldOutline,
      account: mdiAccountCircle,
      techsupport: mdiHeadphones,
      chevron: mdiChevronDown,
      UserInfo: {
        email: null,
        name: null,
        sub: null,
        balance: null
      },
      MenuUser: [
        { text: 'Настройки аккаунта' },
        { text: 'Выйти' }
      ],
      MenuSupport: [
        {
          text: 'Написать в поддержку',
          icon: mdiMessageProcessing
        },
        {
          text: 'Написать в телеграм',
          icon: mdiSendVariant
        }
      ],
      LogInModal: {
        show: false,
        email: '',
        password: ''
      },
      SignUpModal: {
        show: false,
        full_name: '',
        email: '',
        password: '',
        password_repeat: ''
      },
      error_msg: {
        LogInEmail: [],
        LogInPassword: [],
        SignUpFullName: [],
        SignUpEmail: []
      }
    }
  },
  watch: {
    '$route.path': {
      handler: 'fetchUser',
      immediate: true
    },
    '$route.query': {
      handler: 'fetchUser'
    }
  },
  methods: {
    fetchUser: function () {
      clearInterval(this.polling)
      this.fetchUserInfo()
      this.polling = setInterval(() => {
        this.fetchUserInfo()
      }, 30000)
    },
    fetchUserInfo: function() {
      this.$store.dispatch('user/info').then(response => {
        this.UserInfo.email = response.data.email || null
        this.UserInfo.sub = response.data.sub || null
        this.UserInfo.name = response.data.name || null
        this.UserInfo.balance = response.data.balance || null
        if (this.connection == null) {
          this.ConnectUserSocket(this.UserInfo.sub)
        }
        bus.$emit('UserInfo', this.UserInfo)
      }).catch(error => {
        console.log(error)
        if (this.connection != null) {
          this.connection.close(1000, "close connection")
        }
        this.UserInfo = {
          email: null,
          name: null,
          sub: null,
          balance: null
        }
        bus.$emit('UserInfo', this.UserInfo)
      })
    },
    openLogInModal: function () {
      this.clear_err_msg()
      this.closeSignUpModal()
      this.LogInModal.show = true
    },
    closeLogInModal: function () {
      this.LogInModal.show = false
      this.LogInModal.username = ''
      this.LogInModal.password = ''
    },
    openSignUpModal: function () {
      this.clear_err_msg()
      this.closeLogInModal()
      this.SignUpModal.show = true
    },
    closeSignUpModal: function () {
      this.SignUpModal = {
        show: false,
        full_name: '',
        email: '',
        password: '',
        password_repeat: ''
      }
    },
    clear_err_msg: function () {
      this.error_msg = {
        LogInPassword: [],
        LogInEmail: [],
        SignUpFullName: [],
        SignUpEmail: [],
        SignUpPassword: [],
        SignUpPasswordRepeat: []
      }
    },
    LogIn: function (data) {
      if (this.validateLogIn(data) == false) {
        return 0
      }
      let params ={
        email: data.email,
        password: data.password
      }
      this.$store.dispatch('user/login', params).then(response => {
        this.UserInfo.email = response.data.email || null
        this.UserInfo.sub = response.data.sub || null
        this.UserInfo.name = response.data.name || null
        this.UserInfo.balance = response.data.balance || null
        if (this.connection == null) {
          this.ConnectUserSocket(this.UserInfo.sub)
        }
        bus.$emit('UserInfo', this.UserInfo)
        this.closeLogInModal()
      }).catch(error => {
        if (error.response && error.response.data && error.response.status) {
          if (error.response.status == 401 || error.response.status == 400) {
            this.error_msg.LogInPassword = ['Некорректная пара емайл/пароль']
          } else if (error.response.status == 403) {
            this.error_msg.LogInEmail = ['Пользователь имеет ограниченный доступ']
          } else {
            this.$toasted.error('Что-то пошло не так... Попробуйте позднее', { position: 'top-center' }).goAway(3000)
          }
        } else {
          this.$toasted.error('Что-то пошло не так... Попробуйте позднее', { position: 'top-center' }).goAway(3000)
        }
      })
    },
    SignUp: function (data) {
      if (this.validateSignUp(data) == false) {
        return 0
      }
      let params = {
        email: data.email,
        password: data.password,
        full_name: data.full_name,
      }
      this.$store.dispatch('user/signup', params).then(
        this.closeSignUpModal()
      ).catch(error => {
        if (error.response && error.response.data && error.response.status) {
          if (error.response.status == 400 && error.response.data.error == "This user allredy exists") {
            this.error_msg.SignUpEmail = ['Пользователь с таким логином уже существует']
          } else if ((error.response.status == 400 && error.response.data.error == "invalid credentionals")) {
            this.$toasted.error('Данные не прошли проверку валидации', { position: 'top-center' }).goAway(3000)
          } else {
            this.$toasted.error('Что-то пошло не так... Попробуйте позднее', { position: 'top-center' }).goAway(3000)
          }
        } else {
          this.$toasted.error('Что-то пошло не так... Попробуйте позднее', { position: 'top-center' }).goAway(3000)
        }
      })
    },
    ConnectUserSocket: function(userId) {
      if (this.connection != null) {
        this.connection.close(1000, "close connection")
      }

      this.connection = new WebSocket(process.env.VUE_APP_WEBSOCKET_PROTOCOL + "://" + window.location.hostname + process.env.VUE_APP_WEBSOCKET_URL + userId + "/")

      this.connection.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if (data.confirmation == null) {
          data["confirmation"] = "Message received"
          delete data.type
          this.connection.send(JSON.stringify(data));
          return 0;
        }
        if (data.status == 'SUCCEEDED') {
          this.$toasted.success(data.message, {
              position: 'bottom-right'
          }).goAway(5000);
        } else if (data.status == 'PENDING') {
          this.$toasted.info(data.message, {
              position: 'bottom-right'
          }).goAway(5000);
        } else if (data.status == 'CANCELED') {
          this.$toasted.error(data.message, {
              position: 'bottom-right'
          }).goAway(5000);
        } else if (data.status == 'WAITING_FOR_CAPTURE') {
          this.$toasted.info(data.message, {
              position: 'bottom-right'
          }).goAway(5000);
        } else {
          this.$toasted.error(data.message, {
              position: 'bottom-right'
          }).goAway(5000);
        }
      };
    },
    validateSignUp: function(data) {
      let _return = true
      if (data.full_name == '') {
        this.error_msg.SignUpFullName = ['Поле обязательно для заполнения']
        _return = false
      }
      if (data.email == '') {
        this.error_msg.SignUpEmail = ['Поле обязательно для заполнения']
        _return = false
      }
      if (data.email != '' && !data.email.includes('@')) {
        this.error_msg.SignUpEmail = ['Некорректный емайл']
        _return = false
      }
      if (data.password == '') {
        this.error_msg.SignUpPassword = ['Поле обязательно для заполнения']
        _return = false
      }
      if (data.password_repeat == '') {
        this.error_msg.SignUpPasswordRepeat = ['Поле обязательно для заполнения']
        _return = false
      }
      if (data.password_repeat != data.password) {
        this.error_msg.SignUpPasswordRepeat = ['Пароли не совпадают']
        _return = false
      }
      return _return
    },
    validateLogIn: function(data) {
      let _return = true
      if (data.email == '') {
        this.error_msg.LogInEmail = ['Поле обязательно для заполнения']
        _return = false
      }
      if (data.email != '' && !data.email.includes('@')) {
        this.error_msg.LogInEmail = ['Некорректный емайл']
        _return = false
      }
      if (data.password == '') {
        this.error_msg.LogInPassword = ['Поле обязательно для заполнения']
        _return = false
      }
      return _return
    },
    validateLogin: function (login) {
      if (login.includes('@')){
        this.error_msg.LogIn403 = ['Нельзя использовать @ в логине']
        this.error_msg.LogIn401 = []
        return false
      }
      this.error_msg.LogIn403 = []
      this.error_msg.LogIn401 = []
      return true
    },
    validatePassword: function () {
      this.error_msg.LogInPassword = []
      this.error_msg.SignUpPassword = []
      this.error_msg.SignUpPasswordRepeat = []
      return true
    },
    validateEmail: function () {
      this.error_msg.SignUpEmail = []
      this.error_msg.LogInEmail = []
      return true
    },
    validateFullName: function () {
      this.error_msg.SignUpFullName = []
      return true
    }
  }
}
</script>

<style>
  @import url('font/stylesheet.css');
  .header {
    background-color: #242f3d;
  }
  .icon_chameleon {
    color: #7f91a4;
  }
  .icon_chameleon:hover {
    color: #ffffff;
  }
  .btn_chameleon {
    color: #454cee;
    margin-left: 1%;
  }
  .menu_el {
    background-color: #17212B;
    color: white;
    cursor: pointer;
  }
  .menu_el:hover {
    background-color: #242f3d;
  }
</style>