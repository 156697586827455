import axios from 'axios'

const instance = axios.create({
  baseURL: window.location.protocol + '//' + window.location.hostname + process.env.VUE_APP_API_URL,
  headers: {
    common: {
      "Accept": "application/json, application/hal+json",
      "Content-Type": "application/json"
    }
  },
  timeout: 500000,
  withCredentials: true,
});

export default instance;
