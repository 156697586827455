import api from '../../../plugins/api';

export default {
  ping: function () {
    return api.get('/ping/ping')
  },
  login: function (_, payload) {
    return api.post('/user/login/', payload)
  },
  signup: function (_, payload) {
    return api.post('/user/signup/', payload)
  },
  info: function () {
    return api.get('/user/info/')
  },
}
