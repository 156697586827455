import Vue from 'vue'
import Vuex from 'vuex'
import ModuleUser from './modules/user'
import ModuleBilling from './modules/billing'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user: ModuleUser,
        billing: ModuleBilling
    },
})

export default store
